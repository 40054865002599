<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Transaction Adjustment",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Transaction Adjustment",
      items: [
        {
          text: "Transaction",
          href: "/"
        },
        {
          text: "Adjustment",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "transaction.customerName", sortable: true, label: "Customer Name" },
        {key: "transaction.agentName"},
        {
          key: "transaction.transactionData.changes.transactionDate",
          sortable: true,
          label: "Transaction Date",
        },
        {
          key: "transaction.transactionData.changes.transactionAmount",
          sortable: true,
          label: "Transaction Amount (GHS)",
        },
        {
          key: "transaction.transactionData.changes.paymentTypeId",
          sortable: true,
          label: "Payment Type",
        },
        { key: "transaction.transactionType" , label: "Transaction Type"},
        // { key: "transaction.transactionPaymentType", label: "Individaul/Group" },
        { key: "transaction.hasBeenAdjusted", label: "Adjustment Status " },
        {key: "_id", label: "Action"}
      ],
      form: {
        amount: "",
        reason: "",
      },
      adjustments: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    form: {
      amount: { required },
      reason: { required },
    },
  },
  computed:{
     /**
     * Total no. of records
     */
    rows() {
      return this.adjustments.length;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
     async getAdjustments() {
      try {
        const response = await axios.get(
          `${
            process.env.VUE_APP_BASE_URL
          }/adjust-transaction-request/all`
        );
        if (response.status === 200 || response.status === 201) {
          this.adjustments = response.data.data.reverse()
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
  },
  mounted(){
    this.getAdjustments()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
   
    <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Transactions Adjusted</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="adjustments"
                :fields="fields"
                responsive="sm"
                stacked="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template
                  v-slot:cell(transaction.transactionData.changes.paymentTypeId)="row"
                >
                  <div
                    v-if="row.value == 1"
                    class="badge badge-soft-success font-size-12"
                  >
                    Cash Payment
                  </div>
                  <div
                    v-if="row.value == 2"
                    class="badge badge-soft-warning font-size-12"
                  >
                    Momo Payment
                  </div>
                </template>
                <template v-slot:cell(transaction.transactionType)="row">
                  <div v-if="row.value == 'LoanPayment'" class="badge badge-success font-size-12">Loan Payment</div>
                  <div v-if="row.value == 'SavingsDeposit'" class="badge badge-warning font-size-12">
                    Cash Collateral
                  </div>
                  <div v-if="row.value == 'Withdrawal'" class="badge badge-danger font-size-12">Withdrawal</div>
                </template>
                <template v-slot:cell(transaction.notified)="row">
                  <div
                    v-if="row.value == true"
                    class="badge badge-soft-success font-size-12"
                  >
                    Message Sent
                  </div>
                  <div
                    v-if="row.value == false"
                    class="badge badge-soft-danger font-size-12"
                  >
                    Failed
                  </div>
                </template>
                 <template v-slot:cell(transaction.hasBeenAdjusted)="row">
                  <div
                    v-if="row.value == true"
                    class="badge badge-soft-success font-size-12"
                  >
                    Adjusted
                  </div>
                  <div
                    v-if="row.value == false"
                    class="badge badge-soft-danger font-size-12"
                  >
                    Not Adjusted
                  </div>
                </template>
                <template v-slot:cell(_id)="row">
                  <router-link :to="`/admin/adjustments/approve/${row.value}`">
                    <b-button variant="danger" class="btn-sm">
                      <i class="ri-eye-line"></i> Details
                    </b-button>
                  </router-link>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Layout>
</template>